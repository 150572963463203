import React, { Component } from "react";
import styled, { withTheme } from "styled-components";
import { connect } from "react-redux";

import {
  Grid,
  Hidden,
  AppBar as MuiAppBar,
  IconButton as MuiIconButton,
  Toolbar as MuiToolbar,
} from "@material-ui/core";

import { Menu as MenuIcon } from "@material-ui/icons";
import LocalStorageService from "./../services/LocalStorageService";

import UserMenu from "./UserMenu";

const AppBar = styled(MuiAppBar)`
  background: ${props => props.theme.header.background};
  color: ${props => props.theme.header.color};
  box-shadow: ${props => props.theme.shadows[1]};
`;

const Toolbar = styled(MuiToolbar)`
  background-color: #FFF;
  box-shadow: 0 0 14px 0 rgba(53,64,82,.05);
`;
const IconButton = styled(MuiIconButton)`
  svg {
    width: 22px;
    height: 22px;
  }
`;
const localStorageService = LocalStorageService.getService();

const Breadcrumb = styled.div`
  background-color: ${props => props.theme.header.background};
  display: none;
  position: relative;
  width: 100%;
  ${props => props.theme.breakpoints.up("md")} {
    display: block;
  }
  font-weight: bold;
  color: #606673;
`;

const User = styled.div`
  background-color: ${props => props.theme.header.background};
  display: none;
  position: relative;
  width: 100%;
  ${props => props.theme.breakpoints.up("md")} {
    display: block;
  }
  font-weight: bold;
  color: #606673;
`;

class Header extends Component {

  constructor(props) {
    super(props);
    this.state = { userName: '', roleName: '' };
  }

  componentDidMount() {
    let userInfor = JSON.parse(localStorageService.getUserInfor());
    if(userInfor){
      this.setState({ userName: userInfor.fullName, roleName: userInfor.roleName })
    }
  }

  render() {
    let { onDrawerToggle, breadcrumb } = this.props;
    let { userName, roleName } = this.state;
    return (
      <React.Fragment>
        <AppBar position="sticky" elevation={0}>
          <Toolbar >
            <Grid container alignItems="center">
              <Hidden mdUp>
                <Grid item>
                  <IconButton
                    // color="inherit"
                    aria-label="Open drawer"
                    onClick={onDrawerToggle}
                  >
                    <MenuIcon />
                  </IconButton>
                </Grid>
              </Hidden>
              <Grid item>
                <Breadcrumb>
                  {breadcrumb}
                </Breadcrumb>
              </Grid>
              <Grid item xs />
              <Grid item>
                <User>
                  {userName} - {roleName}  <UserMenu />
                </User>
              </Grid>
            </Grid>
          </Toolbar>
        </AppBar>
      </React.Fragment>
    );
  }
}

const mapStateToProps = (store) => {
  return {
    breadcrumb: store.breadcrumbReducer.currentBreadcrumb
  }
};

const mapDispatchToProps = (dispatch, props) => {
  return {}
}
export default connect(mapStateToProps, mapDispatchToProps)(withTheme(Header));

