import React from "react";

import async from "../components/Async";

import {
  Users,
  ShoppingCart,
  Printer,
  Database,
  Clipboard,
  File,
  FileText,
  Layers,
  // Truck,
  Lock,
  HardDrive,
  FilePlus,
  Globe, Package
} from "react-feather";

// Auth components
const SignIn = async(() => import("../pages/auth/SignIn"));
const ResetPassword = async(() => import("../pages/auth/ResetPassword"));
const ChangePassword = async(() => import("../pages/auth/ChangePassword"));
const ResetPasswordSuccess = async(() => import("../pages/auth/ResetPasswordSuccess"));

const Page404 = async(() => import("../pages/auth/Page404"));
const Page500 = async(() => import("../pages/auth/Page500"));

// Order components
const Order = async(() => import("../pages/order/Order"));

const TransferEpacket = async(() => import("../pages/transferEpacket/TransferEpacket"));

// const DeliveryOrder = async(() => import("../pages/order/DeliveryOrder"));

// const PrintOrder = async(() => import("../pages/order/PrintOrder"));

const Pricing = async(() => import("../pages/pricing/Pricing"));

const Purchase = async(() => import("../pages/purchase/Purchase"));

const ComponentToPrint = async(() => import("../pages/order/ComponentToPrint"));

const Report = async(() => import("../pages/report/Report"));

const Debt = async(() => import("../pages/debt/Debt"));

const PrintReceipt = async(() => import("../pages/debt/PrintReceipt"));

const Entry = async(() => import("../pages/entry/Entry"));
const User = async(() => import("../pages/user/User"));
const Os = async(() => import("../pages/os/Os"));

const Role = async(() => import("../pages/role/Role"));

// const Overview = async(() => import("../pages/overview/Overview"));

const CreateOrder = async(() => import("../pages/order/CreateOrder"));

const PrintBill = async(() => import ("../pages/debt/PrintBill"));

const ReturnOrderList = async(() => import("../pages/order/ReturnOrderList"));

const APIOS = async(() => import("../pages/apiOS/APIOS"));

const APHPW = async(() => import("../pages/apiHPW/APHPW"));

// const ReportDeliveryOrder = async(() => import("../pages/order/ReportDeliveryOrder"));

const PrintTracking = async(() => import("../pages/apiOS/PrintTracking"));

const PrintHPWTracking = async(() => import("../pages/apiHPW/PrintHPWTracking"));

const OrderInternational = async(() => import("../pages/order-international/Index"));

const OrderEcomm = async(() => import("../pages/order-ecomm/Index"));

// const QuickUpdateStatus = async(() => import("../pages/quick-update-status"));


const dashboardsRoutes = {
  id: "",
  path: "/",
  component: Order,
  children: null,
  permission: 1
};

const authRoutes = {
  id: "Auth",
  path: "/auth",
  icon: <Users />,
  children: [
    {
      path: "/auth/sign-in",
      name: "Sign In",
      component: SignIn
    },
    {
      path: "/auth/reset-password",
      name: "Reset Password",
      component: ResetPassword
    },
    {
      path: "/auth/change-password",
      name: "Change Password",
      component: ChangePassword
    },
    {
      path: "/auth/reset-password-success",
      name: "Reset Password Success",
      component: ResetPasswordSuccess
    },
    {
      path: "/auth/404",
      name: "404 Page",
      component: Page404
    },
    {
      path: "/auth/500",
      name: "500 Page",
      component: Page500
    }
  ],
  permission: "0"
};

const pickupOrderRoute = {
  id: "Lấy hàng",
  path: "/order",
  icon: <ShoppingCart />,
  children: null,
  component: Order,
  permission: "1"
};

const transferEpacketRoute = {
  id: "Kho hàng",
  path: "/transfer-epacket",
  icon: <Package />,
  children: null,
  component: TransferEpacket,
  permission: "12"
};

// const printOrderRoute = {
//   id: "In vận đơn",
//   path: "/print-order",
//   icon: <Printer />,
//   children: null,
//   component: PrintOrder,
//   permission: "2"
// }

const entryRoute = {
  id: "Nhập liệu",
  path: "/entry",
  icon: <Clipboard />,
  children: null,
  component: Entry,
  permission: "3"
};

// const coordinateRoute = {
//   id: "Giao hàng",
//   path: "/coordinate-delivery",
//   icon: <Truck />,
//   children: null,
//   component: DeliveryOrder,
//   permission: "4"
// };

// const reportDeliveryOrderRoute = {
//   id: "Báo cáo giao hàng",
//   path: "/report-delivery-order",
//   icon: <Truck />,
//   children: null,
//   component: ReportDeliveryOrder,
//   permission: "14"
// };

const reportRoute = {
  id: "BÁO CÁO",
  path: "/report",
  component: Report,
  children: null,
  icon: <File/>,
  permission: "6"
};

const pricingRoute = {
  id: "Bảng giá bán",
  path: "/pricing",
  component: Pricing,
  children: null,
  icon: <Layers/>,
  permission: "7"
};

const purchaseRoute = {
  id: "Bảng giá mua",
  path: "/purchase",
  component: Purchase,
  children: null,
  icon: <HardDrive/>,
  permission: "20"
};

const crossCheckRoute = {
  id: "Công nợ",
  path: "/cross-check",
  component: Debt,
  children: null,
  icon: <FileText/>,
  permission: "8"
};

const managementRoute = {
  id: "Tài khoản",
  path: "/user",
  component: User,
  children: null,
  icon: <Database/>,
  permission: "9"
};

const roleRoute = {
  id: "Phân quyền",
  path: "/role",
  component: Role,
  children: null,
  icon: <Lock/>,
  permission: "19"
};

const componentPrintRoute = {
  id: "component to print",
  path: "/component-print",
  children: null,
  component: ComponentToPrint,
  permission: "2"
}

const printBillRoute = {
  id: "print bil;",
  path: "/print-bill",
  children: null,
  component: PrintBill,
  permission: "8"
}

const printReceiptRoute = {
  id: "print receipt",
  path: "/print-receipt",
  children: null,
  component: PrintReceipt,
  permission: "8"
}

const printTrackingRoute = {
  id: "print tracking",
  path: "/print-tracking",
  children: null,
  component: PrintTracking,
  permission: "13"
}

const printHPWTrackingRoute = {
  id: "print hpw tracking",
  path: "/print-international-tracking",
  children: null,
  component: PrintHPWTracking,
  permission: "13"
}

// const overviewRoutes = {
//   id: "Tổng quan",
//   path: "/overview",
//   icon: <Home/>,
//   children: null,
//   component: Overview,
//   permission: "10"
// };

const createOrderRoute = {
  id: "Tạo đơn hàng",
  path: "/create-order",
  icon: <FilePlus/>,
  children: null,
  component: CreateOrder,
  permission: "11"
};

const returnOrderRoute = {
  id: "Quản lý kho",
  path: "/return-order",
  icon: <ShoppingCart />,
  children: null,
  component: ReturnOrderList,
  permission: "12"
};

const apiOSRoute = {
  id: "API Outsource",
  path: "/api-os",
  icon: <ShoppingCart />,
  children: null,
  component: APIOS,
  permission: "13"
};

const apiHPWRoute = {
  id: "API Quốc tế",
  path: "/api-international",
  icon: <ShoppingCart />,
  children: null,
  component: APHPW,
  permission: "13"
};

const orderInternationalRoute = {
  id: "Hàng quốc tế",
  path: "/order-international",
  icon: <Globe />,
  children: null,
  component: OrderInternational,
  permission: "17"
};

const orderEcommRoute = {
  id: "EPACKET",
  path: "/order-international-ecomm",
  icon: <Package />,
  children: null,
  component: OrderEcomm,
  permission: "17"
};


// const quickUpdateStatusRoute = {
//   id: "Báo cáo nhanh nđ",
//   path: "/quick-update-status",
//   icon: <Package />,
//   children: null,
//   component: QuickUpdateStatus,
//   permission: "17"
// };

const osListRoute = {
  id: "Vender",
  path: "/os",
  icon: <Users />,
  children: null,
  component: Os,
  permission: "22"
};


export const dashboard = [
  dashboardsRoutes,
  // overviewRoutes,
  // printOrderRoute,
  createOrderRoute,
  // pickupOrderRoute,
  // returnOrderRoute,
  apiOSRoute,
  apiHPWRoute,
  orderInternationalRoute,
  orderEcommRoute,
  // quickUpdateStatusRoute,
  entryRoute,
  // coordinateRoute,
  // reportDeliveryOrderRoute,
  reportRoute,
  pricingRoute,
  crossCheckRoute,
  purchaseRoute,
  managementRoute,
  componentPrintRoute,
  osListRoute,
  transferEpacketRoute,
  printReceiptRoute,
  roleRoute,
  printBillRoute,
  printTrackingRoute,
  printHPWTrackingRoute
];

export const auth = [authRoutes];

export default [
  // overviewRoutes,
  createOrderRoute,
  // pickupOrderRoute,
  // returnOrderRoute,
  // printOrderRoute,
  entryRoute,
  // quickUpdateStatusRoute,
  // coordinateRoute,
  // reportDeliveryOrderRoute,
  // apiOSRoute,
  apiHPWRoute,
  orderInternationalRoute,
  orderEcommRoute,
  // reportRoute,
  pricingRoute,
  purchaseRoute,
  crossCheckRoute,
  osListRoute,
  transferEpacketRoute,
  managementRoute,
  roleRoute
];
