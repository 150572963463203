import React, { Component } from "react";
import styled, { withTheme } from "styled-components";
import { withRouter } from 'react-router-dom';

import {
  Menu,
  MenuItem,
  Drawer,
  IconButton as MuiIconButton,
} from "@material-ui/core";

import {
  Power
} from "react-feather";

// import { removeUserStore } from "./../models/index"
import LocalStorageService from "./../services/LocalStorageService";
import ChangePassword from "./ChangePassword";

const IconButton = styled(MuiIconButton)`
  svg {
    width: 22px;
    height: 22px;
  }
`;

const localStorageService = LocalStorageService.getService();

class UserMenu extends Component {

  constructor(props) {
    super(props);
    this.state = {
      anchorMenu: null
    };
  }



  toggleMenu = event => {
    this.setState({ anchorMenu: event.currentTarget });
  };

  closeMenu = (action) => {
    this.setState({ anchorMenu: null });
    if (action === 'signOut') {
      localStorageService.clearToken()
      localStorageService.clearUserInfor()
      this.props.history.push('/auth/sign-in')
    }

  };

  toggleDrawer = (open) => {
    // this.closeMenu('');
    this.setState({ isOpen: open });
  };

  render() {
    const { anchorMenu } = this.state;
    const open = Boolean(anchorMenu);

    return (
      <React.Fragment>
        <IconButton
          aria-owns={open ? "menu-appbar" : undefined}
          aria-haspopup="true"
          onClick={this.toggleMenu}
          color="inherit"
        >
          <Power />
        </IconButton>
        <Menu
          id="menu-appbar"
          anchorEl={anchorMenu}
          open={open}
          onClose={() => this.closeMenu()}
        >
          <MenuItem
            onClick={() => this.toggleDrawer(true)}
          >
            Change Password
          </MenuItem>
          <MenuItem
            onClick={() => {
              this.closeMenu('signOut');
            }}
          >
            Sign out
          </MenuItem>
          <Drawer anchor="right" open={this.state.isOpen} onClose={() => this.toggleDrawer(false)}>
            <ChangePassword />
          </Drawer>
        </Menu>
      </React.Fragment >
    );
  }
}

export default withRouter(withTheme(UserMenu));