export default {
  queryRouteList: '/routes',

  queryUserInfo: '/user',
  logout: '/user/logout',
  login: 'POST /auth/login',
  resetPassword: 'POST /forgot-password',
  checkTokenValidate: 'GET /forgot-password',
  changePassword: 'PUT /forgot-password/:id',
  changePasswordU: 'POST /change-password',

  facebookLogin: 'GET /hybridauth/login token',

  queryPostList: '/posts',
  queryDashboard: '/dashboard',

  queryOrders: 'GET /order',
  createOrder: 'POST /order',
  getPostOrder: 'POST /order',
  updateOrders: 'PUT /order',
  exportTemplate: 'GET /template-export',
  queryOrder: 'GET /order/:id',
  exportEcommOrder: "GET /order-international-ecom-export",

  queryCities: 'GET /city',
  queryDistricts: 'GET /district',
  updateDistricts: 'PUT /district',
  queryWards: 'GET /ward',
  queryCountries: 'GET /country',

  queryCustomers: 'GET /customer',
  listCustomers: 'GET /customer',
  createCustomer: 'POST /customer',
  updateCustomer: 'PUT /customer/:id',
  deleteCustomer: 'DELETE /customer/:id',

  queryMailmans: 'GET /mailman',

  queryPricings: 'GET /pricing',
  updatePricings: 'PUT /pricing',
  createPricing: 'POST /pricing',

  queryReports: 'GET /report',
  exportReport: 'GET /report-export',
  exportDelivery: 'GET /delivery-export',

  queryDebts: 'GET /debt',
  exportDebt: 'GET /debt-export',

  queryEntrys: 'GET /entry',
  updateEntrys: 'PUT /entry',
  queryUserRole: 'GET /user',
  queryUsers: 'GET /user',
  queryRole: 'GET /role',
  queryPermission: 'GET /permission',
  updateRolePermission: 'POST /role-permission',
  queryRolePermission: 'GET /role-permission',
  createOrUpdateUser: 'POST /user',
  activeOrInactiveUser: 'PUT /user/:id',
  deleteUser: 'DELETE /user/:id',

  queryistOrderGroupByStatus: 'GET /overview',
  queryBills: 'GET /bill',
  createBill: 'POST /bill',
  updateBill: 'PUT /bill',

  queryTracking: 'GET /customer/tracking',

  queryOrderWarehouse: 'GET /warehouse',
  queryOrdersByPartnerAPI: 'GET /partner-api/order',
  createOrdersByPartnerAPI: 'POST /partner-api/order',
  disconnectOS: 'PUT /partner-api/order',
  exportOverview: 'GET /overview-export',
  exportReturnOrder: 'GET /return-order-export',
  getShipmentFee: 'GET /shipment-fee',
  createEmailSetting: 'POST /email-setting',
  querySetting: 'GET /email-setting',
  queryTrackingPartnerAPI: 'GET /partner-api/tracking/:id',

  queryStatusInternational: 'GET /status-international',
  queryOrderInternational: 'GET /order-international/:id',
  queryOrderInternationals: 'GET /order-international',
  createOrderInternational: 'POST /order-international',
  addOrderInternationalsStatus: 'PUT /order-international',

  updatePricingsInternational: 'PUT /pricing-international',
  queryPricingsInternational: 'GET /pricing-international',
  importPricingInternational: 'POST /pricing-international',

  importPurchaseInternational: 'POST /purchase-international',
  updatePurchaseInternational: 'PUT /purchase-international',
  listPurchaseInternational: 'GET /purchase-international',

  activeOrInactiveOs: 'PUT /os/:id',
  createOrUpdateOs: 'POST /os',
  deleteOs: 'DELETE /os/:id',
  osList: 'GET /os'


}
