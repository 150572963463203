import { combineReducers } from 'redux';

import themeReducer from './themeReducers';
import breadcrumbReducer from './breadcrumbReducers';


export default combineReducers({
	themeReducer,
	breadcrumbReducer
});
