module.exports = {
  // apiDomain: 'https://api-ecos.fourwals.com',
  // cusDomain: 'https://cus-ecos.fourwals.com',
  cusDomain: 'https://hnn.hanoinhanh.com',
  apiDomain: 'https://api.hanoinhanh.com',
  // apiDomain: 'http://apistreamak.tk',
  // apiDomain: 'http://local.ecos-backend.vn',
  dateTimeFormat: 'DD/MM/YYYY HH:mm:ss',
  dateFormat: 'YYYY/MM/DD',
  apiPrefix: '',
  dateTimeHHMMFormat: 'DD/MM/YYYY HH:mm',
  reCaptchaKey: '6LfTbtsUAAAAAO4w46Z75QUh7fsQPJ24CYYSCcPA'
}
