import React from "react";

export default function Settings() {

  return (
    <React.Fragment>
      {/* <Fab color="primary" aria-label="Edit" onClick={toggleDrawer(true)}>
        <PaletteIcon />
      </Fab>
      <Drawer anchor="right" open={state.isOpen} onClose={toggleDrawer(false)}>
        <Demos />
      </Drawer> */}
    </React.Fragment>
  );
}
